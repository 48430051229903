import { useEffect } from "react";
import Container from "../layout/Container";
import Breadcrumbs from "../layout/Breadcrumbs";
import H1 from "../layout/H1";

function WorkWithUs() {
  useEffect(() => {
    document.title = "Trabalhe Conosco – NG | Engenharia";
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        "content",
        "Junte-se à nossa equipe na NG Engenharia! Trabalhamos com projetos de grande porte. Somos especialistas em infraestrutura rodoviária, com ampla experiência na implantação e restauração de rodovias."
      );
  }, []);

  return (
    <section className="about-us">
      <H1>Trabalhe Conosco</H1>
      <Breadcrumbs />
      <Container>
        <h2 className="title">Junte-se à nossa equipe na NG Engenharia!</h2>
        <div>
          <p>
            Trabalhamos com projetos de grande porte. Somos especialistas em
            infraestrutura rodoviária, com ampla experiência na implantação e
            restauração de rodovias.
          </p>

          <p>
            Realizamos obras de infraestrutura urbana, incluindo pavimentação de
            vias, execução de drenagem pluvial e instalação de sistemas de
            esgotamento sanitário. Contamos com uma equipe de urbanização para
            atuar com projetos de construção de ciclovias, praças e calçadas, e
            em obras de artes especiais, como pontes e passarelas.
          </p>
        </div>
      </Container>
      <Container>
        <div className="width_100_percent">
          <h2 className="text-center">Por que trabalhar conosco?</h2>
          <p>
            <strong>Crescimento Profissional</strong>: Oferecemos oportunidades
            de desenvolvimento através de treinamentos, cursos e projetos
            desafiadores.
            <br />
            <strong>Ambiente Inovador</strong>: Trabalhe com as tecnologias mais
            avançadas do mercado em uma empresa que valoriza a inovação e
            soluções criativas.
            <br />
            <strong>Cultura de Segurança e Sustentabilidade</strong>:
            Priorizamos a segurança no trabalho e incentivamos práticas que
            respeitam o meio ambiente.
            <br />
            <strong>Diversidade e Inclusão</strong>: Valorizamos a diversidade e
            buscamos criar um ambiente onde todos se sintam acolhidos e
            respeitados.
          </p>
        </div>
      </Container>
      <Container>
        <div>
          <h2 className="text-center">Como se candidatar?</h2>

          <p>
            Para fazer parte da nossa equipe, envie seu currículo para nosso
            banco de talentos. Assim que surgir uma oportunidade compatível,
            entraremos em contato.
          </p>

          <a
            className="btn btn-round-right ml-auto mr-auto"
            href="mailto:rh@ngengenharia.com.br"
          >
            <span>Trabalhe conosco!</span>
          </a>

          <p>
            Caso tenha dúvidas sobre o processo de candidatura ou outras
            questões, não hesite em entrar em contato conosco pelo telefone:
            (61) 3341-2646.
          </p>

          <p>
            Na NG Engenharia, estamos sempre à procura de pessoas apaixonadas e
            dedicadas para se juntarem à nossa equipe. Estamos ansiosos para
            conhecer você!
          </p>
        </div>
      </Container>
    </section>
  );
}

export default WorkWithUs;

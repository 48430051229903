import { useEffect } from "react";
import { Link } from "react-router-dom";
import H1 from "../layout/H1";
import Breadcrumbs from "../layout/Breadcrumbs";
import ContainerBlock from "../layout/ContainerBlock";

function Page404() {  
  
  useEffect(() => {
  document.title = "NG | Engenharia - Página não encontrada";
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute(
      "content",
      "Desculpe, a página não que você esta tentando acessar não existe mais ou foi movida."
    );
  }
}, []);

return (
  <section>
    <H1>404 - Página não encontrada</H1>
    <Breadcrumbs/>
    <ContainerBlock>
    <div id="internalPage" className="container text-center">
      <h2 className="first_title">Página não encontrada</h2>
      <p className="text-center mb_30">
        Desculpe, a página que você esta tentando acessar não existe mais ou
        foi movida
      </p>
      <Link to='/' className='btn btn-round-right ml-auto mr-auto'>
        <span>Página inicial</span>
      </Link>
    </div>
    </ContainerBlock>
  </section>
);
};

export default Page404;
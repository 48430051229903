import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Breadcrumbs.module.css';
import ContainerBlock from './ContainerBlock';

const Breadcrumbs = () => {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    const activeLinks = document.querySelectorAll('a.active');
    const breadcrumbItems = Array.from(activeLinks).map((link) => ({
      text: link.textContent,
      href: link.getAttribute('href'),
    }));
    setBreadcrumbs(breadcrumbItems);
  }, [location]);

  // JSON-LD para os breadcrumbs
  const breadcrumbList = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": breadcrumbs.map((breadcrumb, index) => ({
      "@type": "ListItem",
      "position": index + 2,
      "name": breadcrumb.text,
      "item": `${window.location.origin}${breadcrumb.href}`
    }))
  };

  // Adiciona o item "Home" como o primeiro da lista
  breadcrumbList.itemListElement.unshift({
    "@type": "ListItem",
    "position": 1,
    "name": "Home",
    "item": `${window.location.origin}/`
  });

  return (
    <>
      <nav className={styles.breadcrumbs} aria-label="breadcrumb">
        <ContainerBlock>
          <ul itemScope itemType="https://schema.org/BreadcrumbList">
            <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
              <Link to="/" itemProp="item">
                <span itemProp="name">Home</span>
              </Link>
              <meta itemProp="position" content="1" />
            </li>
            {breadcrumbs.map((breadcrumb, index) => (
              <li key={index} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                <Link to={breadcrumb.href} itemProp="item">
                  <span itemProp="name">{breadcrumb.text}</span>
                </Link>
                <meta itemProp="position" content={index + 2} />
              </li>
            ))}
          </ul>
        </ContainerBlock>
      </nav>
      {/* Renderiza o JSON-LD para os breadcrumbs */}
      <script type="application/ld+json">
        {JSON.stringify(breadcrumbList)}
      </script>
    </>
  );
};

export default Breadcrumbs;

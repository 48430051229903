import { useState, useEffect } from "react";
import Container from "../layout/Container";
import { Link, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";
import GalleryPortfolio from "../layout/GalleryPortfolio";
import Breadcrumbs from "../layout/Breadcrumbs";
import H1 from "../layout/H1";

function Portfolio() {
  const { link } = useParams();
  const [portfolio, setPortfolio] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [gallery, setGallery] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPortfolio = async () => {
      try {
        const res = await fetch(
          `https://ngengenharia.com.br/api/portfolio.php?link=${link}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await res.json();
        if (data === null) {
          navigate("/portfolios");
        } else {
        setPortfolio(data);
        document.title = `${data.title} – NG | Engenharia`;
        document
          .querySelector('meta[name="description"]')
          .setAttribute(
            "content",
            `${data.description
              .replace(/<\/?[^>]+(>|$)/g, "")
              .substring(0, 150)}...`
          );}
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPortfolio();
  }, [link]);

  useEffect(() => {
    if (portfolio !== null) {
      let galleryContructor = [];
      for (let i = 1; i <= portfolio.img_end; i++) {
        let item = {
          small: `/images/portfolio/${portfolio.link}/${portfolio.main_image}-0${i}-320x.webp`,
          link: `/images/portfolio/${portfolio.link}/${portfolio.main_image}-0${i}`,
        };
        galleryContructor.push(item);
      }
      setGallery(galleryContructor);
    }
  }, [portfolio]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <section id="portfolioPage">
        <H1>Portfólio | {portfolio.title}</H1>
        <Breadcrumbs />

        <div className="main">
          <Container>
            <picture className="imgMain">
              <source
                media="(max-width: 320px)"
                srcSet={`/images/portfolio/${portfolio.link}/${portfolio.main_image}-320x.webp`}
              />
              <source
                media="(max-width: 480px)"
                srcSet={`/images/portfolio/${portfolio.link}/${portfolio.main_image}-480x.webp`}
              />
              <source
                media="(max-width: 768px)"
                srcSet={`/images/portfolio/${portfolio.link}/${portfolio.main_image}-768x.webp`}
              />
              <source
                media="(max-width: 1024px)"
                srcSet={`/images/portfolio/${portfolio.link}/${portfolio.main_image}-1024x.webp`}
              />
              <source
                media="(max-width: 1280px)"
                srcSet={`/images/portfolio/${portfolio.link}/${portfolio.main_image}-1280x.webp`}
              />
              <img
                width="1280"
                src={`/images/portfolio/${portfolio.link}/${portfolio.main_image}-1280x.webp`}
                alt={portfolio.title}
              />
            </picture>

            <div className="textPortfolio">
              <div className="portfolioInformations">
                <p>
                  <b>Cliente:</b> {portfolio.client}
                </p>
                <p>
                  <b>Local:</b> {portfolio.location}
                </p>
              </div>

              <div id="galeriaPortfolio">
                <GalleryPortfolio
                  items={gallery}
                  title={portfolio.title}
                  link={`/images/portfolio/${portfolio.link}/${portfolio.main_image}`}
                />
              </div>

              {parse(portfolio.description)}
            </div>
          </Container>
          <Container>
            {portfolio.prev && (
              <p>
                <strong>Anterior:</strong>&nbsp;
                <Link to={`/portfolios/${portfolio.prev.link}`}>
                  {portfolio.prev.title}
                </Link>
              </p>
            )}
            {portfolio.next && (
              <p>
                <strong>Próximo:</strong>&nbsp;
                <Link to={`/portfolios/${portfolio.next.link}`}>
                  {portfolio.next.title}
                </Link>
              </p>
            )}
          </Container>
        </div>
      </section>
    </>
  );
}

export default Portfolio;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function UpdateHeader() {
  const location = useLocation();

  useEffect(() => {
    // Scroll para o topo quando a rota mudar
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  useEffect(() => {
    const updateMetaTags = () => {
      const pageTitle = document.title;
      const metaDescriptionElement = document.querySelector('meta[name="description"]');
      const pageDescription = metaDescriptionElement?.getAttribute("content") || "";

      // Atualiza o link canonical
      const canonicalUrl = window.location.origin + location.pathname.replace(/\/$/, "");
      let canonicalLink = document.querySelector('link[rel="canonical"]');
      if (!canonicalLink) {
        canonicalLink = document.createElement("link");
        canonicalLink.setAttribute("rel", "canonical");
        document.head.appendChild(canonicalLink);
      }
      canonicalLink.setAttribute("href", canonicalUrl);

      // Função para atualizar meta tags
      const updateMetaTag = (property, content) => {
        let metaTag = document.querySelector(`meta[property="${property}"]`);
        if (!metaTag) {
          metaTag = document.createElement("meta");
          metaTag.setAttribute("property", property);
          document.head.appendChild(metaTag);
        }
        metaTag.setAttribute("content", content);
      };

      updateMetaTag("og:url", canonicalUrl);
      updateMetaTag("og:title", pageTitle);
      updateMetaTag("og:description", pageDescription);

      // Atualiza o meta twitter:title
      let twitterTitleMetaTag = document.querySelector('meta[name="twitter:title"]');
      if (!twitterTitleMetaTag) {
        twitterTitleMetaTag = document.createElement("meta");
        twitterTitleMetaTag.setAttribute("name", "twitter:title");
        document.head.appendChild(twitterTitleMetaTag);
      }
      twitterTitleMetaTag.setAttribute("content", pageTitle);

      // Remove qualquer schema dinâmico existente
      document.querySelector('script[type="application/ld+json"][data-schema="dynamic"]')?.remove();

      // Cria o JSON-LD para WebSite
      const jsonLdScript = document.createElement("script");
      jsonLdScript.setAttribute("type", "application/ld+json");
      jsonLdScript.setAttribute("data-schema", "dynamic");

      const jsonLdContent = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "@id": "https://ngengenharia.com.br/#website",
        "url": canonicalUrl,
        "name": pageTitle,
        "inLanguage": "pt-BR",
        "description": pageDescription,
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://ngengenharia.com.br/?s={search_term_string}",
          "query-input": "required name=search_term_string"
        },
        "sameAs": [
          "https://www.facebook.com/people/Ng-Engenharia/61559002567158/",
          "https://www.instagram.com/ngengenhariaeconstrucoes/",
          "https://www.linkedin.com/company/ngengenhariaeconstrucoes/",
          "https://x.com/ngengenharia"
        ]
      };

      jsonLdScript.textContent = JSON.stringify(jsonLdContent);
      document.head.appendChild(jsonLdScript);
    };

    // Observa mudanças no <title> e <meta name="description">
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList" || mutation.type === "attributes") {
          updateMetaTags();
        }
      });
    });

    const titleElement = document.querySelector("title");
    const descriptionElement = document.querySelector('meta[name="description"]');

    if (titleElement) observer.observe(titleElement, { childList: true });
    if (descriptionElement) observer.observe(descriptionElement, { attributes: true });

    // Executa a atualização inicial
    updateMetaTags();

    // Limpa o observer ao desmontar
    return () => observer.disconnect();
  }, [location]);

  return null;
}

export default UpdateHeader;

import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useParams, useNavigate } from 'react-router-dom';
import Breadcrumbs from '../layout/Breadcrumbs';
import H1 from '../layout/H1';
import ContainerBlock from '../layout/ContainerBlock';


function Service() {

    const { link } = useParams();
    const [service, setService] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchService = async () => {
            try {
                const res = await fetch(`https://ngengenharia.com.br/api/services.php?name=${link}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (!res.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await res.json();
                if (data === null) {
                    navigate("/servicos");
                } else {
                    setService(data);

                    document.title = `${data.title} – NG | Engenharia`;
                    document.querySelector('meta[name="description"]').setAttribute('content', `${data.text.replace(/<\/?[^>]+(>|$)/g, '').substring(0, 150)}...`);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchService();
    }, [link]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <section id='servicePage'>
            <H1>Serviço | {service.title}</H1>
            <Breadcrumbs/>
            <ContainerBlock>
                <div className='main'>
                    <img src={`/images/servicos/${service.image}`} alt={service.title} width="450" height="241"/>
                    {parse(service.text)}
                </div>
            </ContainerBlock>
        </section>
    );
}

export default Service;

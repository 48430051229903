import { Link } from "react-router-dom";
import Container from "../../layout/Container";
import ContainerBlock from "../../layout/ContainerBlock";
import Inputs from "../../layout/Inputs";
import { useEffect, useState } from "react";
import Select from "../../layout/Select";
import Textarea from "../../layout/Textarea";
import Breadcrumbs from "../../layout/Breadcrumbs";
import H1 from "../../layout/H1";
import MessageForm from "../../layout/MessageForm";

function IntegrityChannel() {
  useEffect(() => {
    document.title = "Canal de Integridade – NG | Engenharia";
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", "");
  }, []);

  const [sendSuccess, setSendSuccess] = useState(null);

  const [formValues, setFormValues] = useState([]);
  const [subCategory, setSubCategory] = useState(null);
  const location = [{ name: "Brasília/DF" }];
  const identification = [{ name: "sim" }, { name: "não" }];
  const perfil = [
    { name: "Anônimo" },
    { name: "Cidadão" },
    { name: "Cliente" },
    { name: "Colaborador" },
    { name: "Comunidade" },
    { name: "Contribuinte" },
    { name: "Ex-Colaborador" },
    { name: "Fornecedor" },
    { name: "Integrante" },
    { name: "Não identificado" },
    { name: "Outros" },
    { name: "Terceirizado" },
    { name: "Terceiro" },
  ];
  const category = [
    {
      name: "Clientes",
      subCategory: [
        { name: "Atendimento ao Cliente" },
        { name: "Entrega de Produto ou Serviço em Situação Irregular" },
        { name: "Gestão Contratual com o Cliente" },
      ],
    },
    {
      name: "Conduta",
      subCategory: [
        { name: "Conduta Inadequada" },
        { name: "Danos a Imagem da OAS" },
        { name: "Fraude à Concorrência" },
        { name: "Oferta de Presentes e Brindes" },
        { name: "Recebimento de Presentes e Brindes" },
        { name: "Relacionamento afetivo amoroso na relação de subordinação" },
        { name: "Represálias ou retaliações" },
        { name: "Suborno / Propina / Corrupção" },
        { name: "Violação de Normas ou Procedimentos" },
      ],
    },
    {
      name: "Conflito de interesses",
      subCategory: [
        { name: "Atividade Políticas e Sindicais" },
        { name: "Contratação de Partes Interessadas" },
        { name: "Empregos ou Atividades Paralelas" },
        { name: "Favorecimento Ilícito" },
      ],
    },
    { name: "Consulta", subCategory: [{ name: "Consulta" }] },
    { name: "Elogio", subCategory: [{ name: "Elogio" }] },
    {
      name: "Financeiro",
      subCategory: [
        { name: "Despesas Corporativas Indevidas" },
        { name: "Desvio de Recursos Financeiros" },
        { name: "Gestão inadequada de recursos" },
        { name: "Pagamentos Indevidos" },
      ],
    },
    {
      name: "Fornecedores",
      subCategory: [
        { name: "Desbloqueio de terceiros" },
        { name: "Fornecedores-Outros" },
        { name: "Gestão inadequada de fornecedores" },
      ],
    },
    {
      name: "Fraude",
      subCategory: [
        { name: "Adulteração de Horas Trabalhadas" },
        { name: "Adulteração de Registros Contábeis e Relatórios Financeiros" },
        { name: "Adulteração de Relatórios Gerenciais Internos" },
        { name: "Adulteração de contratos, relatórios e registros" },
        {
          name: "Atuações relacionadas a fornecedores e prestadores de serviços",
        },
        { name: "Falsificação de Documentos" },
        { name: "Fraude contábil e fiscal" },
        { name: "Lavagem de dinheiro" },
        { name: "Manipulação de Auditorias" },
        { name: "Sonegação de Impostos" },
      ],
    },
    {
      name: "Gestão da Informação",
      subCategory: [
        { name: "Divulgação de informações Confidenciais" },
        { name: "Utilização indevida de e-mail, internet ou mídias sociais" },
        { name: "Violação de Sistemas informatizados e e-mails" },
      ],
    },
    {
      name: "Patrimônio",
      subCategory: [
        { name: "Depredação de Ativos" },
        { name: "Furto, Desvio ou Roubo de Ativos" },
        { name: "Uso indevido de Propriedade da OAS" },
      ],
    },
    {
      name: "Pessoas",
      subCategory: [
        { name: "Agressão Física ou Verbal" },
        { name: "Assédio moral" },
        { name: "Assédio sexual" },
        { name: "Conflito na Relação de Subordinação" },
        { name: "Demissão Injustificada" },
        { name: "Discriminação" },
        { name: "Homologação" },
        { name: "Jornada de Trabalho Excessiva" },
        { name: "Salários, Comissões e Benefícios" },
        { name: "Tratamento Desigual" },
        { name: "Álcool, drogas e armas de fogo" },
      ],
    },
    {
      name: "QSMS",
      subCategory: [
        { name: "Acidentes / quase acidentes" },
        { name: "Condições Inadequadas de Segurança e Saúde no Trabalho" },
        { name: "Condições ambientais inadequadas" },
        { name: "Danos ao Meio Ambiente" },
        { name: "Desvios Comportamentais de Segurança e Saúde" },
        { name: "Falhas de qualidade do serviço / produto" },
        { name: "Risco de acidente envolvendo o entorno" },
      ],
    },
    { name: "Reclamação", subCategory: [{ name: "Reclamação" }] },
    { name: "Sugestão", subCategory: [{ name: "Sugestão" }] },
  ];

  // Função para encontrar a subCategoria pelo nome principal
  function handleChangeSubcategory(name) {
    const categorySelect = category.find((item) => item.name === name);
    setSubCategory(categorySelect ? categorySelect.subCategory : null);
  }

  function handleChange(e) {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
    if (e.target.name === "category") {
      handleChangeSubcategory(e.target.value);
    }
  }

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const res = await fetch(
        "https://ngengenharia.com.br/api/send-integrity-channel.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formValues),
        }
      );
      const data = await res.json();
      if (!res.ok) {
        setSendSuccess(false);
        throw new Error("Erro ao enviar o formulário");
      }
      setSendSuccess(data);
    } catch (error) {
      throw new Error("Erro ao enviar o formulário");
    }
  };

  return (
    <section className="IntegrityChannelPage">
      <H1>Canal de Integridade</H1>
      <Breadcrumbs />

      <div className="main">
        <ContainerBlock>
          <p>
            A NG Engenharia possui uma trajetória marcada por comprometimento e
            competência na execução de seus projetos. Acreditamos que é de
            extrema importância construir e manter um ambiente de trabalho ético
            e transparente, no qual as pessoas depositem orgulho, integridade e
            dedicação.
          </p>

          <p>
            O Código de Conduta em Integridade Empresarial da NG estabelece
            regras, princípios e valores que regulam e guiam a ação profissional
            de todos os colaboradores e parceiros.
          </p>

          <p>
            Implementado em 2019, o código de conduta na NG reafirma nosso
            compromisso com valores éticos e o cumprimento de todas as leis
            aplicáveis às nossas atividades econômicas.
          </p>

          <p>
            Todos os colaboradores, entendidos como os empregados, estagiários,
            conselheiros, diretores e quaisquer representantes da NG devem estar
            cientes e agir de acordo com estes princípios em todas as funções e
            atividades do dia a dia. Nosso código de conduta também deve ser
            transmitido e aplicável a todos os fornecedores de bens e serviços,
            bem como às consorciadas e às sócias parceiras da NG. De forma
            constante, a NG conduz esforços para que o código de conduta seja
            assinado e compreendido por todos.
          </p>

          <h3>CANAL DE INTEGRIDADE</h3>

          <p>
            <strong>O que é?</strong>
            <br />O canal de integridade NG é o meio pelo qual podem ser
            comunicadas eventuais violações ao código de conduta, às normas
            internas e à legislação vigente, apresentadas denúncias de fatos que
            acarretem em danos à imagem e operação da NG, bem como esclarecidas
            as dúvidas sobre o conteúdo e sua aplicação. As comunicações são
            analisadas pelo comitê de <i>compliance</i>, órgão responsável por
            acompanhar o cumprimento das normas, responder às dúvidas
            apresentadas e coordenar as investigações de denúncias, feitas de
            forma confidencial.
          </p>

          <p>
            <strong>
              Qual sua importância?
              <br />
            </strong>
            Essa ferramenta é de fundamental importância no programa de
            integridade da empresa, pois as condutas praticadas e comunicadas
            serão avaliadas para que sejam tomadas as devidas providências.
          </p>

          <p>
            <strong>Como funciona?</strong>
            <br />O processo interno funciona da seguinte forma: o manifestante
            registra relato por meio de canal de denúncia (Canal de
            Integridade), que pode ser identificado ou anônimo. O comitê de{" "}
            <i>compliance</i> é o responsável pela análise e coordenação das
            investigações sobre o fato apresentado, além de responder às dúvidas
            expressadas. Após conclusão, o comitê tomará uma decisão e
            estabelecerá as ações corretivas necessárias para o caso. Por
            motivos de segurança e confidencialidade, a conclusão da apuração é
            comunicada apenas aos manifestantes que se identificarem. Para
            contatar o canal de integridade da NG é importante ter previamente
            as seguintes informações:
          </p>

          <ul>
            <li>Onde, como e quando ocorreu o fato?</li>
            <li>Quem são os supostos envolvidos e responsáveis?</li>
            <li>Quais os motivos aparentes?</li>
          </ul>

          <p>
            <strong>Quais públicos atende?</strong>
            <br />O canal de integridade NG atende a todos os colaboradores
            (diretos ou indiretos), clientes, fornecedores e parceiros que
            desejam realizar comunicações de boa-fé, com responsabilidade e
            compromisso ético. Vale ressaltar que a apresentação de falsas
            acusações ou informações incorretas e inverídicas constitui uma
            violação ao código de ética.
          </p>

          <Container>
            <Link
              to="/canal-de-integridade/codigo-de-conduta-da-ng"
              className="btn btn-round-right ml-auto mr-10"
            >
              <span>Clique aqui para consultar o Código de Conduta da NG</span>
            </Link>
            <Link
              to="/canal-de-integridade/politica-de-integridade-empresarial-da-ng"
              className="btn btn-round-right ml-auto mr-10"
            >
              <span>
                Clique aqui para consultar a Política de Integridade Empresarial
                da NG
              </span>
            </Link>
          </Container>

          <div className="bg_gray">
            <p>
              <strong>
                O Canal de Integridade da NG oferece a qualquer interessado,
                incluindo colaboradores, fornecedores e parceiros, os seguintes
                meios de comunicação:
              </strong>
            </p>
            <ul>
              <li>
                Mensagens eletrônicas pela internet acessando o endereço:{" "}
                <Link to="/canal-de-integridade">
                  http://ngengenharia.com.br/canaldeintegridade/
                </Link>
              </li>
              <li>
                Mensagens eletrônicas para:{" "}
                <a href="mailto:canaldeintegridade@ngengenharia.com.br">
                  canaldeintegridade@ngengenharia.com.br
                </a>
              </li>
              <li>Mensagens eletrônicas via WhatsApp: (61) 3341-2646</li>
            </ul>
          </div>
          {sendSuccess === null && (
            <form onSubmit={submitForm} className="form">
              <div className="flexForm">
                <Select
                  name="location"
                  options={location}
                  value={formValues.location ? formValues.location : ""}
                  label="Localidade:*"
                  handleOnChange={handleChange}
                />
                <Inputs
                  type="text"
                  name="name"
                  value={formValues.name ? formValues.name : ""}
                  label="Seu nome:*"
                  handleOnChange={handleChange}
                />
                <Select
                  name="identification"
                  options={identification}
                  value={
                    formValues.identification ? formValues.identification : ""
                  }
                  label="Deseja se identificar?*"
                  handleOnChange={handleChange}
                />
                {formValues.identification === "sim" && (
                  <>
                    <Inputs
                      type="text"
                      name="phone"
                      value={formValues.phone ? formValues.phone : ""}
                      label="Telefone para Contato:*"
                      handleOnChange={handleChange}
                    />
                    <Inputs
                      type="email"
                      name="email"
                      value={formValues.email ? formValues.email : ""}
                      label="E-mail:*"
                      handleOnChange={handleChange}
                    />
                    <Inputs
                      type="text"
                      name="reDocument"
                      value={formValues.reDocument ? formValues.reDocument : ""}
                      label="Número de Documento/RE:*"
                      handleOnChange={handleChange}
                    />
                  </>
                )}
                <Select
                  name="perfil"
                  options={perfil}
                  value={formValues.perfil ? formValues.perfil : ""}
                  label="Perfil (Qual seu relacionamento com a empresa?)"
                  handleOnChange={handleChange}
                />
                <Inputs
                  type="text"
                  name="unit"
                  value={formValues.unit ? formValues.unit : ""}
                  label="Unidade:*"
                  handleOnChange={handleChange}
                />
                <Inputs
                  type="text"
                  name="state"
                  value={formValues.state ? formValues.state : ""}
                  label="Cidade/Estado:*"
                  handleOnChange={handleChange}
                />
                <Select
                  name="category"
                  options={category}
                  value={formValues.category ? formValues.category : ""}
                  label="Categoria*"
                  handleOnChange={handleChange}
                />

                {subCategory !== null && (
                  <Select
                    name="subCategory"
                    options={subCategory}
                    value={formValues.subCategory ? formValues.subCategory : ""}
                    label="SubCategoria*"
                    handleOnChange={handleChange}
                  />
                )}
              </div>

              <h3>
                Prezado(a), confira algumas orientações para que você possa
                descrever o seu relato.
              </h3>

              <ol>
                <li>O que aconteceu? Informe o máximo de detalhes possível.</li>
                <li>Quando aconteceu a situação relatada?</li>
                <li>
                  Onde aconteceu a situação? (Local, setor, lugar, empresa).
                </li>
                <li>
                  Quais os nomes das pessoas envolvidas? (Denunciados,
                  testemunhas).
                </li>
                <li>Como você teve conhecimento da situação?</li>
                <li>
                  Você tem conhecimento do motivo pelo qual a situação ocorreu?
                </li>
                <li>
                  A situação relatada é de conhecimento da empresa? Caso
                  positivo, qual o(s) nome(s) da(s) pessoa(s) que tem
                  conhecimento?
                </li>
                <li>Se cabível, é possível medir o prejuízo em números?</li>
              </ol>
              <Textarea
                name="descricao"
                value={formValues.descricao ? formValues.descricao : ""}
                label="Descrição:"
                handleOnChange={handleChange}
              />

              <input
                className="btn btn-round-right ml-auto"
                type="submit"
                value="Enviar"
              />
            </form>
          )}

          {sendSuccess !== null && (
            <div className="width50percent">
              <MessageForm success={sendSuccess} />
            </div>
          )}
        </ContainerBlock>
      </div>
    </section>
  );
}

export default IntegrityChannel;
